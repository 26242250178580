import type { Ref } from 'vue'

export function useTree(nodes: Ref<Tree[]>) {
  const mapCache = new Map<number, Tree>()

  function initMapCache() {
    if (mapCache.size > 0)
      return
    mapCache.clear()
    forEachNode(nodes.value, (node) => {
      mapCache.set(node.node.id, node)
    })
  }

  function forEachTree(consumer: (node: Tree) => void) {
    forEachNode(nodes.value, consumer)
  }

  // 遍历树
  function forEachNode(nodes: Tree[], consumer: (node: Tree) => void) {
    forEachNodeInner(nodes, consumer, 1)
  }

  function forEachNodeInner(nodes: Tree[], consumer: (node: Tree) => void, deep: number) {
    if (!nodes)
      return

    for (const node of nodes) {
      consumer(node)
      node.deep = deep
      forEachNodeInner(node?.children ?? [], consumer, deep + 1)
    }
  }

  // 找到根节点的第一个子节点
  function findRootFirstChild(node: Tree): Tree | null {
    if (!node.children || node.children.length === 0)
      return node

    const firstChild = node.children[0]
    return firstChild.children && firstChild.children.length > 0 ? findRootFirstChild(firstChild) : firstChild
  }

  // 获取平铺的树
  function getTree() {
    initMapCache()
    return mapCache
  }

  // 根据节点id获取节点
  function getNodeById(id: number) {
    initMapCache()
    return mapCache.get(id)
  }

  // 查询指定节点的父级节点
  function findAllParents(id: number, includeSelf: boolean = true) {
    initMapCache()
    const result: Tree[] = []
    const self = mapCache.get(id)

    if (!self)
      return result

    if (includeSelf)
      result.push(self)

    let parentId = self.parentId
    while (parentId !== 0 && parentId != null) {
      const parentNode = mapCache.get(parentId)
      if (parentNode) {
        result.unshift(parentNode)
        parentId = parentNode.parentId
      }
      else {
        break
      }
    }

    return result
  }

  return { getTree, getNodeById, findAllParents, forEachTree, findRootFirstChild }
}
